//Import just for async polyfill load
import 'core-js/es/promise';
import '@ungap/global-this';

import { IS_SENTRY_ENABLED } from './components/common/constants/constants';

import './gntEvents';

let requiredPollyfills = [];

//ResizeObserver support
if (!window.ResizeObserver)
    requiredPollyfills.push(import(/* webpackChunkName: "observer-polyfill" */ './polyfillObserver'));

//ES6 support
if (typeof Symbol === 'undefined')
    requiredPollyfills.push(import(/* webpackChunkName: "es6-polyfill" */ './polyfill'));

//Object.fromEntries support iOS 12 and older
if (typeof Object.fromEntries === 'undefined')
    requiredPollyfills.push(import(/* webpackChunkName: "fromEntries-polyfill" */ 'core-js/modules/es.object.from-entries'));

//Array.prototype.flatMap support Opera Mobile
if (typeof Array.prototype.flatMap === 'undefined')
    requiredPollyfills.push(import(/* webpackChunkName: "flatMap-polyfill" */ 'core-js/modules/es.array.flat-map'));

//Navigator.sendBeacon support
if (!window?.navigator?.sendBeacon)
    requiredPollyfills.push(import(/* webpackChunkName: "sendBeacon-polyfill" */ './polyfillSendBeacon'));

//Raportowanie błędów Sentry
if (IS_SENTRY_ENABLED)
    requiredPollyfills.push(import(/* webpackChunkName: "sentry-client" */ './sentry'));

//Wait for all imports then run app
Promise.all(requiredPollyfills).then(() => {
    import(/* webpackChunkName: "app-main" */'./main');
});